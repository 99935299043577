import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'multiFilter'
})
export class MultiFilterPipe implements PipeTransform {
  transform(items: any[], filterValues: { [key: string]: string }, filterProperties: string[]): any[] {
    console.log(items,filterValues,filterProperties);

    if (!items) {
      return [];
    }
    if (!filterValues || !filterProperties || filterProperties.length === 0) {
      return items;
    }
    return items.filter((item) => {
      return filterProperties.every(prop => {
        const filterValue = filterValues[prop];
        if (!filterValue) {
          return true;
        }
        return item[prop] && item[prop].toString().toLowerCase().includes(filterValue.toLowerCase());
      });
    });
  }
}
