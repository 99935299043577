import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gfilter' //g for general
})
export class GFilterPipe implements PipeTransform {

  transform(items: any[], value: string, props: string): any[] {
    
    if (!items) {
      return [];
    }
    if (!value || !props) {
      return items;
    }
    return items.filter((item) => {
      const keys: string[] = deepKeys(item);
      
      const foundKey = keys.find(
        (key) => {
          return key.includes(`${props}`);
        },
      );
      if (!foundKey) return;

      const valueOfKey = getValueOfKey(
        item,
        foundKey,
      );

      return valueOfKey && valueOfKey.toString().toLowerCase().includes(value.toLowerCase());
    });

  }
}

function deepKeys(object: any) {
  function iter(o: any, p: any[]) {
      if (Array.isArray(o)) { return; }
      if (o && typeof o === 'object') {
          var keys = Object.keys(o);
          if (keys.length) {
              keys.forEach(function (k) { iter(o[k], p.concat(k)); });
          }
          return;
      }
      result.push(p.join('.'));
  }
  const result: any[] = [];

  iter(object, []);
  
  return result;
}

function getValueOfKey(
  obj: any,
  key: string,
): any {
  if (!key.includes('.')) {
    return obj[key];
  }

  const splitted = key.split('.');
  const part = splitted[0];
  const newKey = splitted[1];

  return getValueOfKey(
    obj[part],
    newKey,
  );
}
