import { IWallet } from '../models/interfaces/wallet.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: IWallet[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }
    return items.filter((item) => {
      return item.currency === value;
    });

  }
}
